<script setup>
import { useSnackbarStore } from '@/store/snackbar';
import { storeToRefs } from 'pinia';
import { useDisplay } from 'vuetify';

const { smAndUp } = useDisplay();
const { isVisible, actionName, message, type } =
  storeToRefs(useSnackbarStore());
const bottom = computed(() => {
  if (smAndUp) return `40px`;

  return `72px`;
});

const snackbarStore = useSnackbarStore();
</script>

<template>
  <v-snackbar
    v-model="isVisible"
    location="bottom"
    :color="type"
    :style="`margin: ${bottom}`"
  >
    <div class="text-subtitle-1">{{ message }}</div>

    <template #actions>
      <v-btn @click="snackbarStore.action()">
        {{ actionName }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
